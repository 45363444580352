
































































import { defineComponent, getCurrentInstance } from "@vue/composition-api";

export default defineComponent({
  layout: "authenticated",
  setup(): Record<string, unknown> {
    const instance = getCurrentInstance();
    const logout = () => instance?.proxy?.$microsoft?.doLogout();

    return {
      logout,
    };
  },
});
